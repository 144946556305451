<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <div class="shadow border-0">
            <CCard class="p-4">
              <div>
                <h1>Login</h1>
                <p class="text-muted">Sign In as an admin</p>
                <form @submit.prevent="login">
                  <div class="form-group">
                    <input
                      type="email"
                      required
                      placeholder="email"
                      v-model="email"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      placeholder="password"
                      v-model="password"
                      required
                    />
                  </div>
                  <button
                    :disabled="loading"
                    v-if="!loading"
                    class="btnd btn-success text-white"
                    type="submit"
                  >
                    Login
                  </button>
                  <button
                    v-else
                    class="btnd btn-success"
                    type="button"
                    disabled
                  >
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </button>
                </form>
              </div>
            </CCard>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
// import Calls from "@/Calls";
// import axios from "axios";
import { mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
    }),
    async login() {
      this.loading = true;

      try {
        const response = await this.signIn({
          email: this.email,
          password: this.password,
        });
        this.$toast.open({
          message: response.msg,
          position: "top-right",
          type: "success",
          duration: 2000,
          pauseOnHover: true,
        });
        setTimeout(() => {
          this.$router.push("/dashboard");
        }, 1500);
      } catch (error) {
        this.$toast.open({
          message: error.msg,
          position: "top-right",
          type: "error",
          duration: 2000,
          pauseOnHover: true,
        });
      }
      this.loading = false;
    },
  },
};
</script>
